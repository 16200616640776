import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';

export default function PostPage({ pageContext }) {
    const { data } = pageContext;

    return (
        <Layout>
            <Seo
                title="Insights"
                description="Introducing you to our Customer Success Stories."
            />
            {data ? (
                <>
                    <div className="inttop"></div>
                    <div className="app-content featurebg">
                        <div className="container">
                            <div className="insightpost">
                                <div className="insightpost__top">
                                    <div className="postimg">
                                        {data.localFile &&
                                        data.localFile.childImageSharp ? (
                                            <>
                                                <Image
                                                    src=""
                                                    dynamic={
                                                        data.localFile
                                                            .childImageSharp
                                                            .gatsbyImageData
                                                    }
                                                    alt={data.title}
                                                />
                                            </>
                                        ) : (
                                            <Image
                                                src="insight-ph.png"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="insightpost__bot">
                                    <Link to="/insights">
                                        <h1>{data.title}</h1>
                                    </Link>
                                    {parse(data.content)}
                                    <Link className="btn" to="/insights">
                                        Back to insights
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </Layout>
    );
}
